<template>
  <div class="forgot-password">
    <v-card width="500">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange darken-2"
      ></v-progress-linear>
      <v-card-title>Forgot password</v-card-title>
      <v-card-text>
          <v-text-field
            type="email"
            color="orange"
            v-model="email"
            prepend-icon="mdi-account"
            placeholder="Email Address"
          ></v-text-field>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              block large dark
              color="orange"
              :loading="loading"
              :disabled="loading"
              @click="resetPassword"
            >Reset Password</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'Login',
  data() {
    return {
      error: false,
      message: '',
      email: '',
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      this.loading = true;

      await Auth.forgotPassword(this.email)
        .then((data) => {
          console.log(data); // eslint-disable-line
        })
        .catch((error) => {
          console.log('error sending password reset', error); // eslint-disable-line
          this.loading = false;
          this.error = true;
          this.message = error.message;
        });
    },
  },
};
</script>
