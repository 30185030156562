<template>
  <div class="forgot-password">
    <v-container>
      <v-row align="center" justify="center">
        <v-col>
          <ForgotPasswordComponent />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import ForgotPasswordComponent from '@/components/auth/ForgotPasswordComponent.vue';

export default {
  name: 'ForgotPassword',
  props: {
    user: String,
  },
  metaInfo: {
    title: 'Forgot Password',
  },
  components: {
    ForgotPasswordComponent,
  },
};
</script>
